import { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail, signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { Button, Form, Alert, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [iframeSrc, setIframeSrc] = useState("https://app.powerbi.com/view?r=eyJrIjoiZWVlMzQzOGQtODkxYi00MTJlLWE0YmYtM2ZhNTQ1NzYxOGE5IiwidCI6IjUyMDEzYmQ2LWExYWMtNGE5Mi1hZDNhLTE0YzcyMzU5MmNlNSJ9");
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError("Erreur de connexion. Veuillez vérifier vos informations.");
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setError("Veuillez entrer une adresse email valide.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage("Un email de réinitialisation a été envoyé. Vérifiez votre boîte de réception.");
    } catch (error) {
      setError("Erreur lors de l'envoi de l'email. Veuillez réessayer.");
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Erreur lors de la déconnexion", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIframeSrc(prevSrc => prevSrc);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <div>Chargement...</div>;
  }

  return (
    <Container className="mt-5">
      {!currentUser ? (
        showResetPassword ? (
          // Formulaire de réinitialisation de mot de passe
          <Row className="justify-content-center">
            <Col md={6}>
              <h2 className="text-center mb-4">Réinitialisation du mot de passe</h2>

              {error && <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>}
              {successMessage && <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>{successMessage}</Alert>}

              <Form onSubmit={handleResetPassword}>
                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Entrez votre email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" block>
                  Réinitialiser le mot de passe
                </Button>
              </Form>

              <div className="mt-3 text-center">
                <Button variant="link" className="text-muted" onClick={() => setShowResetPassword(false)}>
                  Retour à la connexion
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          // Formulaire de connexion
          <Row className="justify-content-center">
            <Col md={6}>
              <h2 className="text-center mb-4">EliTech on Power-BI</h2>

              {error && <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>}

              <Form onSubmit={handleLogin}>
                <Form.Group controlId="formBasicEmail" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Entrez votre email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className="mb-3">
                  <Form.Label>Mot de passe</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Entrez votre mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" block>
                  Se connecter
                </Button>
              </Form>

              <div className="mt-3 text-center">
                <Button variant="link" className="text-muted" onClick={() => setShowResetPassword(true)}>
                  Mot de passe oublié ?
                </Button>
              </div>
            </Col>
          </Row>
        )
      ) : (
        // Tableau de bord Power BI
        <>
          <Row className="justify-content-between align-items-center">
            <Col xs={6}>
              <h2 className="text-primary">Tableau de bord</h2>
            </Col>
            <Col xs={6} className="text-end">
              <Button className="text-bg-danger" onClick={handleLogout}>
                Déconnexion
              </Button>
            </Col>
          </Row>
          <iframe
            title="Power BI Report"
            src={iframeSrc}
            width="100%"
            height="800px"
            allowFullScreen={true}
            style={{ border: 'none', marginTop: '2rem' }}
          ></iframe>
        </>
      )}
    </Container>
  );
}

export default App;
