// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAgdaDJ2gfht6zW4Y2leyrbfRzFr0IfBxk",
  authDomain: "elitechpowerbi.firebaseapp.com",
  projectId: "elitechpowerbi",
  storageBucket: "elitechpowerbi.appspot.com",
  messagingSenderId: "401733279789",
  appId: "1:401733279789:web:e3acbf852b3b8e9c1fd8a4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };